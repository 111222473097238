<script setup lang=ts>
import { EventName, LocalStorageKey } from '@/types/enums'

defineProps({
  branches: {
    type: Object,
    default: () => {},
  },
})

defineEmits(['close'])

const itsOk = ref(false)

const accessToken = useLocalStorage(LocalStorageKey.AccessToken, '')

enum PendingScheduleOption {
  Confirm = 'confirm_schedule',
  Cancel = 'cancel_schedule',
}

const confirmed = ref<string[]>([])
const canceled = ref<string[]>([])

function selectOption(orderDetailsArr: any[], scheduleOption: PendingScheduleOption, scheduledDate: string) {
  for (const orderDetails of orderDetailsArr) {
    confirmed.value = confirmed.value.filter(order => order.orderDetailsId !== orderDetails.orderDetailsId)
    canceled.value = canceled.value.filter(order => order.orderDetailsId !== orderDetails.orderDetailsId)

    if (scheduleOption === PendingScheduleOption.Confirm) {
      confirmed.value.push({ ...orderDetails, scheduledDate })
    }
    else {
      canceled.value.push({ ...orderDetails, scheduledDate })
    }
  }
}

const loading = ref(false)

function getOrderDetailsByOrder(orders: any[], options: any = { byScheduledDate: false }) {
  const res = orders.reduce((acc: any, item: any) => {
    const data = item.orderId

    if (!acc[data]) {
      acc[data] = !options.byScheduledDate ? [] : {}
    }

    if (!options.byScheduledDate) {
      acc[data].push(item.orderDetailsId)

      return acc
    }

    if (!acc[data][item.scheduledDate])
      acc[data][item.scheduledDate] = []

    acc[data][item.scheduledDate].push(item.orderDetailsId)

    return acc
  }, {})

  return res
}

async function save() {
  try {
    loading.value = true

    const confirmedBody = getOrderDetailsByOrder(confirmed.value, { byScheduledDate: true })

    track(EventName.ScheduleConfirmed, {
      accepted: confirmed.value.length,
      refused: canceled.value.length,
    })

    await $fetch('/api/procedure/schedule', {
      method: 'POST',
      headers: {
        AuthToken: accessToken.value,
      },
      body: {
        userId: useCustomer().customer.value.id,
        confirmed: confirmedBody,
        canceled: getOrderDetailsByOrder(canceled.value),
      },
    })

    itsOk.value = true
  }
  catch (error: any) {
    errorTracker(error)

    itsOk.value = false
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <div class="min-w-full min-h-screen xl:min-w-[805px] xl:w-[805px] xl:min-h-[580px]">
    <div class="flex items-center justify-between py-6 px-4 w-full shadow">
      <p class="text-xl text-primary-500">
        {{ itsOk ? 'Agendamentos atualizados' : 'Novas datas disponíveis' }}
      </p>

      <Icon name="mdi:close" class="text-primary-400 size-6 cursor-pointer" @click="$emit('close')" />
    </div>

    <!--
      Form
    -->
    <div v-if="branches && Object.values(branches).length && !itsOk">
      <p class="py-3 text-center text-neutral-200 px-4 xl:px-8">
        Infelizmente, as datas que você selecionou previamente para o agendamento dos seus exames não estão disponíveis
        no momento. Abaixo seguem as novas datas para a realização dos seus procedimentos. Caso as opções não atendam
        suas necessidades o procedimento deverá ser cancelado. Por favor, escolha entre as opções abaixo:
      </p>

      <div v-for="branch in Object.values(branches)" :key="branch.id" class="px-4 xl:px-8">
        <div class="flex items-center gap-4 py-4 border-b border-base-50">
          <Img
            :src="branch.labLogo"
            class="min-w-[40px] rounded-lg"
            width="40"
            height="40"
            alt="logo do lab x"
          />

          <div>
            <h3 class="text-xl text-primary-500">
              {{ branch.labName }}
            </h3>

            <p class="text-sm line-clamp-1 text-neutral-200">
              {{ branch.labBranchAddress }}, {{ branch.labBranchNumber }} - {{ branch.labBranchDistrict }}, {{
                branch.labBranchCity }}
            </p>
          </div>
        </div>

        <div
          v-for="schedule in Object.keys(branch.scheduledDate)"
          :key="schedule"
          class="flex flex-col xl:flex-row-reverse my-3"
        >
          <div class="w-full">
            <div class="flex items-center gap-4 xl:gap-0">
              <div class="flex-1 border-b border-base-50">
                <h4 class="text-xs font-semibold text-primary-500">
                  Data
                </h4>

                <span class="text-neutral-200">{{ formatDate(schedule) }}</span>
              </div>
              <div class="flex-1 border-b border-base-50">
                <h4 class="text-xs font-semibold text-primary-500">
                  Horário
                </h4>

                <span class="text-neutral-200">{{ formatTime(schedule) }}</span>
              </div>
            </div>

            <ul>
              <li
                v-for="(scheduled, scheduledIndex) in branch.scheduledDate[schedule]"
                :key="scheduledIndex"
                class="text-neutral-200 border-b border-base-50 py-2"
              >
                <span class="line-clamp-1">
                  {{ scheduled.displayName }}
                </span>
              </li>
            </ul>
          </div>

          <div
            class="text-primary-500 flex items-center gap-3 xl:items-start border-b border-base-50 py-2 xl:flex-col xl:px-3"
          >
            <label
              :for="`confirm-${schedule}`"
              class="flex-1 flex items-center xl:items-start gap-4 cursor-pointer"
            >
              <InputRadio
                :id="`confirm-${schedule}`"
                :name="schedule"
                value="confirm_schedule"
                @change="selectOption(branch.scheduledDate[schedule], PendingScheduleOption.Confirm, schedule)"
              />

              <span>Confirmar</span>
            </label>

            <label
              :for="`cancel-${schedule}`"
              class="flex-1 flex items-center xl:items-start gap-4 cursor-pointer"
            >
              <InputRadio
                :id="`cancel-${schedule}`"
                :name="schedule"
                value="cancel_schedule"
                @change="selectOption(branch.scheduledDate[schedule], PendingScheduleOption.Cancel)"
              />

              <span>Cancelar</span>
            </label>
          </div>
        </div>
      </div>
    </div>

    <!--
      Successfully
    -->
    <div v-if="itsOk" class="flex flex-col gap-6 items-center justify-center px-4 text-center xl:px-16">
      <Img
        src="https://web-cdn.saudetrevo.com.br/illustrations/check_de_sucesso.svg"
        alt="Imagem de sucesso"
        width="233"
        height="189"
        class="mt-12"
      />

      <p class="text-neutral-200">
        Seus agendamentos foram atualizados e as novas datas e horários dos exames podem ser consultados na <span
          class="text-primary-400"
        >Área do Usuário</span>. <strong>Os horários recusados ficaram disponíveis
          imediatamente para outras pessoas.</strong>
      </p>
    </div>

    <!--
      Actions
    -->
    <div class="fixed bottom-0 flex justify-center left-0 right-0 px-6 py-4">
      <Button v-if="itsOk" class="w-[296px]" @click="$emit('close')">
        Voltar para Histórico de Exames
      </Button>

      <Button
        v-else
        class="w-[296px]"
        :loading="loading"
        :disabled="!confirmed.length && !canceled.length"
        @click="save"
      >
        Salvar
      </Button>
    </div>
  </div>
</template>
